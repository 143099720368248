// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-post-tsx": () => import("/Users/derek/Documents/Projects/opensource/byderek.com/src/templates/post.tsx" /* webpackChunkName: "component---src-templates-post-tsx" */),
  "component---src-sketches-random-color-tsx": () => import("/Users/derek/Documents/Projects/opensource/byderek.com/src/sketches/random-color.tsx" /* webpackChunkName: "component---src-sketches-random-color-tsx" */),
  "component---src-pages-404-tsx": () => import("/Users/derek/Documents/Projects/opensource/byderek.com/src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("/Users/derek/Documents/Projects/opensource/byderek.com/src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-subscribe-tsx": () => import("/Users/derek/Documents/Projects/opensource/byderek.com/src/pages/subscribe.tsx" /* webpackChunkName: "component---src-pages-subscribe-tsx" */)
}

